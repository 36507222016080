/* xs Grid sizing */
@media (max-width: 576px) {
    .channelscontainer-channeldisplayname {
        font-size: 1rem;
        font-weight: 700;
    }
    .channelscontainer-icon-partner {
        width: 15px;
        height: 15px;
    }
    .channelscontainer-icon-affiliate {
        width: 10px;
        height: 10px;
    }
    .channelscontainer-channelloginname {
        font-size: 0.75rem;
    }
    .twitch-profile-image {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 10px;
    }
    .GameAccount-account-badge {
        font-size: 0.75rem;
    }
}

/* sm Grid sizing */
@media (min-width: 576px) {
    .channelscontainer-channeldisplayname {
        font-weight: 700;
    }
    .channelscontainer-icon-partner {
        width: 25px;
        height: 25px;
    }
    .channelscontainer-icon-affiliate {
        width: 20px;
        height: 20px;
    }
    .channelscontainer-channelloginname {
    }
    .twitch-profile-image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 10px;
    }
    .GameAccount-account-badge {
        font-size: 1.15rem;
    }
}

/* md Grid sizing */
@media (min-width: 768px) {
    .channelscontainer-channeldisplayname {
        font-weight: 700;
    }
    .channelscontainer-icon-partner {
        width: 25px;
        height: 25px;
    }
    .channelscontainer-icon-affiliate {
        width: 20px;
        height: 20px;
    }
    .channelscontainer-channelloginname {
    }
    .twitch-profile-image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 10px;
    }
    .GameAccount-account-badge {
        font-size: 1.15rem;
    }
}

/* lg Grid sizing */
@media (min-width: 992px) {
    .channelscontainer-channeldisplayname {
        font-weight: 700;
    }
    .channelscontainer-icon-partner {
        width: 25px;
        height: 25px;
    }
    .channelscontainer-icon-affiliate {
        width: 20px;
        height: 20px;
    }
    .channelscontainer-channelloginname {
    }
    .twitch-profile-image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 10px;
    }
    .GameAccount-account-badge {
        font-size: 1.15rem;
    }
}

/* xl Grid sizing */
@media (min-width: 1200px) {
    .channelscontainer-channeldisplayname {
        font-weight: 700;
    }
    .channelscontainer-icon-partner {
        width: 25px;
        height: 25px;
    }
    .channelscontainer-icon-affiliate {
        width: 20px;
        height: 20px;
    }
    .channelscontainer-channelloginname {
    }
    .twitch-profile-image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 10px;
    }
    .GameAccount-account-badge {
        font-size: 1.15rem;
    }
}

/* xxl Grid sizing */
@media (min-width: 1400px) {
    .channelscontainer-channeldisplayname {
        font-weight: 700;
    }
    .channelscontainer-icon-partner {
        width: 25px;
        height: 25px;
    }
    .channelscontainer-icon-affiliate {
        width: 20px;
        height: 20px;
    }
    .channelscontainer-channelloginname {
    }
    .twitch-profile-image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 10px;
    }
    .GameAccount-account-badge {
        font-size: 1.15rem;
    }
}

.AcountSideMenuBadge-link {
    color: #e1e1e1 !important;
    text-decoration: none !important;
}
.AcountSideMenuBadge-link:hover {
    color: #557392;
    text-decoration: none;
}

.GameAccount-navbar-brand {
    background-color: unset !important;
    box-shadow: unset !important;
    padding: 0 !important;
}

.GameAccountSummary-item-heading {
    font-size: 0.65rem;
    font-weight: 700;
}

.GameAccountSummary-legend-icon {
    width: 32px;
    height: 32px;
}

.GameAccountSummary-rank-icon {
    width: 32px;
    height: 32px;
}

.GameAccountSummary-rank-info {
    line-height: 1rem;
}

.GameAccountSummary-rank-score {
    font-size: 0.75rem;
    font-weight: 100;
}

.fake-primary-link {
    color: #557392;
    text-decoration: none;
    cursor: pointer;
}

.fake-primary-link:hover {
    color: #778fa8;
    text-decoration: underline;
}

:root {
    --discord-primary-color: #7289da;
    --twitter-primary-color: #1da1f2;
    --twitch-primary-color: #6441a5;
    --youtube-primary-color: #cd201f;
    --reddit-primary-color: #ff4500;
    --origin-primary-color: #f56c2d;
    --xbox-primary-color: #107b10;
    --playstation-primary-color: #0070d1;
}

.AddGameAccountModal-PCIcon {
    width: 32px;
    height: 32px;
}
.AddGameAccountModal-ConsoleIcon {
    width: 32px;
    height: 32px;
}
